import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars, faCode, faBook, faSignInAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

export const getStaticProps = async () => {
  const timestamp = Date.now(); // Generate timestamp once

  return {
    props: {
      timestamp,
    },
  };
};

interface HeaderProps {
  timestamp?: number; // Accept timestamp as prop
}

const Header1: React.FC<HeaderProps> = ({ timestamp }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? <MobileHeader timestamp={timestamp} /> : <DesktopHeader timestamp={timestamp} />}
    </>
  );
};

const MobileHeader: React.FC<HeaderProps> = ({ timestamp }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className="bg-white py-4 flex justify-between items-center fixed w-full top-0 z-10">
      <div className="pl-4">
        <Link href="/">
          <Image
            src={`/logo.webp?v=${timestamp}`} // Use timestamp here
            alt="iabcodes"
            width={200}
            height={100}
            priority
            className="h-16 w-auto md:h-20 md:max-w-xs max-w-[150px] max-h-20"
          />
        </Link>
      </div>
      {!isOpen && <div className="w-60"></div>}
      <div ref={sidebarRef} className={`bg-primary fixed top-0 right-0 h-full transition-all duration-300 ease-in-out ${isOpen ? 'w-60' : 'w-0'}`}>
        <button className="px-4 py-6 focus:outline-none absolute right-0 mt-4 mr-4" onClick={toggleSidebar} aria-label={isOpen ? 'Close Sidebar' : 'Open Sidebar'}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className={`w-5 h-5 hover:text-secondary ${isOpen ? 'text-white' : ''}`} />
        </button>
        {isOpen && (
          <nav className="py-28 w-60">
            <ul>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/blog" className="flex items-center text-white">
                  <FontAwesomeIcon icon={faBook} className="mr-2 w-5 h-5" />
                  Blog
                </Link>
              </li>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/signup" className="flex items-center text-white">
                  <FontAwesomeIcon icon={faUserPlus} className="mr-2 w-5 h-5" />
                  Signup
                </Link>
              </li>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/login" className="flex items-center text-white">
                  <FontAwesomeIcon icon={faSignInAlt} className="mr-2 w-5 h-5" />
                  Login
                </Link>
              </li>
              <li className="py-2 pl-6 border-t border-gray-300 hover:bg-gray-300">
                <Link href="/documentation" className="flex items-center text-white">
                  <FontAwesomeIcon icon={faCode} className="mr-2 w-5 h-5" />
                  API documentation
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

const DesktopHeader: React.FC<HeaderProps> = ({ timestamp }) => {
  return (
    <header className="bg-white py-4 flex justify-between items-center fixed w-full top-0 z-10">
      <div className="ml-4">
        <Link href="/">
          <Image
            src={`/logo.webp?v=${timestamp}`} // Use timestamp here
            alt="iabcodes"
            width={200}
            height={100}
            priority
            className="h-20 w-auto"
          />
        </Link>
      </div>
      <nav className="flex items-center space-x-6 pr-4">
        <Link href="/blog" className="text-primary hover:text-iabcolour1">
        <span className="flex items-center"><FontAwesomeIcon icon={faBook} className="mr-1 w-5 h-5" />
          Blog</span>
        </Link>
        <Link href="/signup" className="text-primary hover:text-iabcolour1">
        <span className="flex items-center"><FontAwesomeIcon icon={faUserPlus} className="mr-1 w-5 h-5" />
          Signup</span>
        </Link>
        <Link href="/login" className="text-primary hover:text-iabcolour1">
        <span className="flex items-center"><FontAwesomeIcon icon={faSignInAlt} className="mr-1 w-5 h-5" />
          Login</span>
        </Link>
        <Link href="/documentation" className="text-primary hover:text-iabcolour1">
          <span className="flex items-center"><FontAwesomeIcon icon={faCode} className="mr-1 w-5 h-5" />
          API documentation</span>
        </Link>
      </nav>
    </header>
  );
};

export default Header1;

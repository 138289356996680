import Footer from '@/components/Footer';
import Head from 'next/head';
import Header1 from '@/components/Header1';

export default function Home() {
  return (
    <main>
      <Head>    
        <title>Buy Cheap Data Bundles</title>
        <meta name="description" content="Buy cheap data bundles, airtime online for MTN, Glo, Airtel, and 9mobile, cable tv subscriptions, electricity bills payment, education pins. Swiftly process automated services from all service providers." />
        <meta name="keywords" content="cheap-data-bundles, buy-data, airtime, top-up, MTN, Glo, Airtel, 9mobile, etisalat, VTU, cable-tv, dstv, gotv, startimes, showmax, electricity-paymenets, prepaid-meter, postpaid-meter, ikedc, ekedc, aedc, kedco, phed, jed, kaedco, eedc, ibedc, bedc, education-payments, exam-pins, neco, waec, jamb, utme, nabteb" />
      </Head>

      <Header1 />

      <Footer />
    </main>
  );
}
